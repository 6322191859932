import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import {
  Table,
  Typography,
  Grid,
  TableBody,
  TableCell,
  TableRow
} from '@material-ui/core'
import { CorpContext } from '../reducers/Main'
import moment from 'moment'

const useStyle = makeStyles(theme => ({
  paper: {
    background: 'rgb(255, 185, 81)',
    overflow: 'auto',
    padding: theme.spacing(4),
    borderRadius: 10,
    boxShadow: '0 3px 6px 0 rgba(45, 47, 46, 0.15)'
  },
  '@media (min-width:1200px)': {
    paper: {
      width: '70vw',
      padding: theme.spacing(4, 8)
    }
  },
  heading: {
    marginBottom: '10px',
    fontWeight: 600,
    fontSize: '20px',
    color: '#1e201f'
  },
  content: {
    fontSize: '14px',
    fontWeight: '500',
    color: '#1e201f'
  },
  cardLayout: {
    '&:last-child': {
      justifyContent: 'space-between'
    }
  },
  cell: {
    borderBottom: 'none',
    padding: '1px 16px 5px 0px',
    color: '#323232',
    fontSize: '14px',
    fontWeight: 500,
    width: '216px',
    display: 'inline-block',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  currentAmount: {
    color: 'white',
    fontSize: '70px',
    fontWeight: '300'
  },
  recentlyAmount: {
    color: '#1e201f',
    fontSize: '14px'
  },
  departmentAmount: {
    color: '#1e201f',
    fontSize: '16px',
    fontWeight: 500,
    marginBottom: '16px'
  },
  amount_info_item: {
    borderLeft: '2px solid white',
    padding: '0 0 0 55px'
  },
  more: {
    color: 'white',
    fontSize: '14px',
    fontWeight: 600,
    textDecoration: 'none',
    position: 'absolute',
    right: 0,
    bottom: 0
  },
  amount_item: {
    minWidth: '280px'
  },
  '@media (max-width:960px)': {
    amount_item: {
      borderLeft: 'none',
      height: '150px',
      margin: '10px 10px 10px 10px',
      padding: '0'
    },
    amount_info_item: {
      borderLeft: 'none',
      padding: '20px 0 0 0',
      marginTop: '40px',
      borderTop: '2px solid white'
    }
  }
}))

function AmountCard() {
  const classes = useStyle()
  const { state } = useContext(CorpContext)

  function renderDepSummary() {
    return state.groups_summary.map((item, index) => {
      if (item.summary.time === state.summary[0]?.time) {
        return (
          <TableRow key={index}>
            <TableCell className={classes.cell}>
              {item.name} {item.deprecated ? '（已刪除）' : ''}
            </TableCell>
            <TableCell className={classes.cell}>
              {item.summary.deductibleAmount} 元
            </TableCell>
          </TableRow>
        )
      }
    })
  }

  function AmountItem() {
    if (state.summary.length === 0) {
      return <React.Fragment></React.Fragment>
    }
    return state.summary.map((item, index) => {
      const final_amount = item.final_amount || 0
      if (
        item.time ===
        moment()
          .startOf('month')
          .unix()
      ) {
        return (
          <TableRow key={index}>
            <Typography className={classes.content}>
              {moment()
                .startOf('month')
                .year()}
              年 {item.time.toMonth()}月 未結帳總金額
            </Typography>
            <Typography className={classes.currentAmount}>
              {final_amount.toLocaleString('en')}
            </Typography>
          </TableRow>
        )
      }
      return (
        <Typography key={index} className={classes.recentlyAmount}>
          {item.time.toMonth()}月 {getPaymentStatus(item.status)}{' '}
          {final_amount.toLocaleString('en')} 元
        </Typography>
      )
    })
  }

  function getPaymentStatus(str) {
    switch (str) {
      case 'PENDING':
        return '未結帳金額'
      case 'FINISHED':
        return '結帳金額'
      default:
        return ''
    }
  }
  return (
    <React.Fragment>
      <div className={classes.paper}>
        <Typography className={classes.heading}>企業帳務資訊</Typography>
        <Grid container style={{ position: 'relative' }}>
          <Grid item className={classes.amount_item}>
            <AmountItem />
          </Grid>
          <Grid item className={classes.amount_info_item}>
            <Typography className={classes.departmentAmount}>
              各群組 公司支付乘車金額
            </Typography>
            <Table>
              <TableBody>{renderDepSummary()}</TableBody>
            </Table>
          </Grid>
          <Typography
            component={Link}
            to={`/amountcenter`}
            className={classes.more}
          >
            more >
          </Typography>
        </Grid>
      </div>
    </React.Fragment>
  )
}

export default AmountCard
