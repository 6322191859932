import React, { useState, useContext, useEffect } from 'react'
import { isEmpty } from 'lodash'
import { makeStyles } from '@material-ui/core/styles'
import { Paper } from '@material-ui/core'
import PublicTrip from '../components/PublicTrip'
import { CorpContext } from '../reducers/Main'
import { getTripByHash } from "../api";

const useStyle = makeStyles((theme) => ({
  toolbar: theme.mixins.toolbar,
  paper: {
    overflowX: 'auto',
    padding: theme.spacing(5, 8),
    borderRadius: 10,
    width: '70vw',
  },
  heading: {
    marginLeft: '180px',
    marginBottom: '10px',
    fontWeight: 700,
  },
  icon: {
    margin: theme.spacing(1),
    color: 'gray',
    fontSize: '120px',
  },
  cell: {
    borderBottom: 'none',
  },
  '@media (max-width:1200px)': {
    paper: {
      padding: theme.spacing(2),
      width: '100%',
    },
  },
}))

function TripDetail(props) {
  const classes = useStyle()
  const { state, dispatch } = useContext(CorpContext)
  const [trip_info, setTripInfo] = useState()
  const trip_hash = props.match.params.trip_hash // 備用，等 api 新增搜尋 hash 功能
  const { field, group } = props.match.params

	async function getTrip(trip_hash) {
		const data = await getTripByHash(trip_hash)
		if (data.status !== 200) return alert('目前抓不到資料，請稍後再試。')
		const trip_info = data.data.data.trips[0]
		if (trip_info != null && !isEmpty(trip_info)) {
            setTripInfo(trip_info)
            dispatch({
              type: 'UPDATE_TRIP_DETAIL',
              trip_detail: trip_info,
            })
		}
	}
	useEffect(() => {
	}, [trip_info])

	useEffect(() => {
		if (state.trip_detail == null || isEmpty(state.trip_detail)) {
			getTrip(trip_hash)
		} else {
			setTripInfo(state.trip_detail)
			return () => {
				dispatch({
					type: 'UPDATE_TRIP_DETAIL',
					trip_detail: {},
				})
			}
		}
	}, [state.trip_detail])
  return (
    <React.Fragment>
      <div className={classes.toolbar} />
      {!isEmpty(trip_info) && <PublicTrip trip={trip_info} type={field} />}
    </React.Fragment>
  )
}

export default TripDetail
