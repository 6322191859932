import React, { useContext }  from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core'
import { CorpContext } from '../../reducers/Main'
import moment from 'moment'

const useStyles = makeStyles({
  column: {
    padding: '4px 12px'
  },
});

const RejectTripConfirmModal = ({ open, handleClose, handleConfirm }) => {
  const { state } = useContext(CorpContext)

  const deadline = () => {
    const company = state.company[0]
    const reserveTime = moment(state.trip_detail.reserve_time * 1000)
    const billingMonth = reserveTime.date() < company.billing_day ? reserveTime.subtract(1, 'month').startOf('month') : reserveTime.startOf('month')
    return billingMonth.add(company.billing_send_offset_months, 'month').date(company.billing_send_day).subtract(1, 'day').format('YYYY 年 MM 月 DD 日')
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">確定要將此筆行程轉為員工自付?</DialogTitle>
      <DialogContent>
        <Typography variant="caption" style={{ marginTop: 12 }}>
          <span>提醒您：</span>
          <ul style={{ listStyleType: 'disc', marginLeft: 20, marginTop: 4 }}>
            <li>
              確認送出後，系統將發送行程未付款通知給該行程之乘客
            </li>
            <li>
              若乘客未在 {deadline()} 23:00 前完成付款，此筆行程將會併入至本期帳單結算，且無法針對此行程再次操作轉為員工自付。
            </li>
          </ul>
        </Typography>
      </DialogContent>
      <DialogActions style={{ justifyContent: 'center', marginBottom: 12 }}>
        <Button variant="contained" onClick={handleClose} disableElevation>
          取消
        </Button>
        <Button style={{ marginLeft: 40 }} variant="contained" onClick={handleConfirm} color="primary" disableElevation>
          確認
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default RejectTripConfirmModal
