import React, { useState, useContext, useEffect } from 'react'
import moment from 'moment'
import ExcelJS from 'exceljs'
import FileSaver from 'file-saver'
import localized from '../../model/Localize'
import { makeStyles } from '@material-ui/core/styles'
import { Icon, Button, Typography } from '@material-ui/core'
import { CorpContext } from '../../reducers/Main'
import { generalStyle } from '../../styles'
import { getCompanyPayment } from '../../utils'
import { receiptType, tripCategory } from '../../constants'

const useStyles = makeStyles(theme => ({
  text: {
    display: 'inline-block',
    marginRight: '10px'
  },
  export_icon: {
    color: 'white'
  }
}))

function ExportTripAmountTableButton(props) {
  const classes = useStyles()
  const generalClasses = generalStyle()
  const { summery } = props
  const { state } = useContext(CorpContext)

  const [company, setCompany] = useState(null)

  useEffect(() => {
    if (state.company == null || state.company.length < 1) {
      setCompany(null)
    } else {
      setCompany(state.company[0])
    }
    return () => {}
  }, [state.company])

  function agreementFee(agreement) {
    if (!agreement) {
      console.log('HIHI0')
      return 0
    }
    const mAgreement = JSON.parse(agreement)
    if (!mAgreement) {
      return 0
    }
    console.log('mAgreement' + JSON.stringify(mAgreement))
    console.log(mAgreement.company_fee_amount)
    return mAgreement.company_fee_amount || 0
  }

  function agreementDiscount(agreement) {
    if (!agreement) {
      return 0
    }
    const mAgreement = JSON.parse(agreement)
    if (!mAgreement) {
      return 0
    }
    return mAgreement.company_discount_amount || 0
  }

  const handleTripTransactions = row => {
    const { company: companyPayment, self } = getCompanyPayment(row.transactions || [])
    return { company: companyPayment, self }
  }

  async function onExportTripExcel() {
    if (company == null) {
      return
    }

    const workbook = new ExcelJS.Workbook()
    workbook.creator = 'LINE TAXI Business'
    workbook.lastModifiedBy = 'LINE TAXI Business'
    workbook.created = new Date()
    workbook.modified = new Date()
    workbook.lastPrinted = new Date()

    const sheet_p1 = workbook.addWorksheet('付款明細')

    sheet_p1.mergeCells('A1:K1')
    sheet_p1.getCell('A1').value = '觔斗雲大車隊股份有限公司'
    sheet_p1.getCell('A1').alignment = {
      vertical: 'middle',
      horizontal: 'center'
    }

    sheet_p1.mergeCells('A2:K2')
    sheet_p1.getCell('A2').value = 'LINE TAXI 計程車車資明細表'
    sheet_p1.getCell('A2').alignment = {
      vertical: 'middle',
      horizontal: 'center'
    }

    sheet_p1.mergeCells('A3:K3')
    sheet_p1.getCell('A3').value = ' '
    sheet_p1.getCell('A3').alignment = {
      vertical: 'middle',
      horizontal: 'center'
    }

    sheet_p1.mergeCells('A4:B4')
    sheet_p1.getCell('A4').value = '客戶編號：'
    sheet_p1.getCell('A4').alignment = {
      vertical: 'middle',
      horizontal: 'right'
    }

    sheet_p1.mergeCells('C4:F4')
    sheet_p1.getCell('C4').value = company.id
    sheet_p1.getCell('C4').alignment = {
      vertical: 'middle',
      horizontal: 'left'
    }

    sheet_p1.mergeCells('A5:B5')
    sheet_p1.getCell('A5').value = '客戶名稱：'
    sheet_p1.getCell('A5').alignment = {
      vertical: 'middle',
      horizontal: 'right'
    }

    sheet_p1.mergeCells('C5:F5')
    sheet_p1.getCell('C5').value = company.name
    sheet_p1.getCell('C5').alignment = {
      vertical: 'middle',
      horizontal: 'left'
    }

    sheet_p1.mergeCells('A6:B6')
    sheet_p1.getCell('A6').value = '統一編號：'
    sheet_p1.getCell('A6').alignment = {
      vertical: 'middle',
      horizontal: 'right'
    }

    sheet_p1.mergeCells('C6:F6')
    sheet_p1.getCell('C6').value = company.tax_num
    sheet_p1.getCell('C6').alignment = {
      vertical: 'middle',
      horizontal: 'left'
    }

    sheet_p1.mergeCells('A7:B7')
    sheet_p1.getCell('A7').value = '聯絡電話：'
    sheet_p1.getCell('A7').alignment = {
      vertical: 'middle',
      horizontal: 'right'
    }

    sheet_p1.mergeCells('C7:F7')
    sheet_p1.getCell('C7').value = company.mobile_num
      ? company.mobile_num
      : company.telephone_num
    sheet_p1.getCell('C7').alignment = {
      vertical: 'middle',
      horizontal: 'left'
    }

    sheet_p1.mergeCells('A8:B8')
    sheet_p1.getCell('A8').value = '信箱：'
    sheet_p1.getCell('A8').alignment = {
      vertical: 'middle',
      horizontal: 'right'
    }

    sheet_p1.mergeCells('C8:F8')
    sheet_p1.getCell('C8').value = company.email
    sheet_p1.getCell('C8').alignment = {
      vertical: 'middle',
      horizontal: 'left'
    }

    sheet_p1.getCell('G4').value = '對帳期間：'
    sheet_p1.getCell('G4').alignment = {
      vertical: 'middle',
      horizontal: 'right'
    }

    const unixtimeInMs = summery.time * 1000
    const billingDay = company.billing_day
    const start = moment(unixtimeInMs).date(billingDay).format('YYYY / MM / DD')
    const end = moment(unixtimeInMs).add(1, 'month').date(billingDay).subtract(1, 'day').format('YYYY / MM / DD')
    sheet_p1.getCell('H4').value = `${start} ~ ${end}`
    sheet_p1.getCell('H4').alignment = {
      vertical: 'middle',
      horizontal: 'left'
    }

    sheet_p1.getCell('G5').value = '銷帳編號：'
    sheet_p1.getCell('G5').alignment = {
      vertical: 'middle',
      horizontal: 'right'
    }

    sheet_p1.getCell('H5').value = company.id + '-' + summery.time.totUnix()
    sheet_p1.getCell('H5').alignment = {
      vertical: 'middle',
      horizontal: 'left'
    }

    sheet_p1.getCell('G6').value = '客戶簡稱：'
    sheet_p1.getCell('G6').alignment = {
      vertical: 'middle',
      horizontal: 'right'
    }

    sheet_p1.getCell('H6').value = company.nick_name
    sheet_p1.getCell('H6').alignment = {
      vertical: 'middle',
      horizontal: 'left'
    }

    sheet_p1.getCell('G7').value = '繳款期限：'
    sheet_p1.getCell('G7').alignment = {
      vertical: 'middle',
      horizontal: 'right'
    }

    sheet_p1.getCell('H7').value = summery.time
      .toEndDate(
        state.company[0].pending_statement_month,
        state.company[0].pending_statement_day
      )
      .format('YYYY / MM / DD')
    sheet_p1.getCell('H7').alignment = {
      vertical: 'middle',
      horizontal: 'left'
    }

    sheet_p1.getCell('G8').value = '製表日期：'
    sheet_p1.getCell('G8').alignment = {
      vertical: 'middle',
      horizontal: 'right'
    }

    sheet_p1.getCell('H8').value = moment().format('YYYY / MM / DD')
    sheet_p1.getCell('H8').alignment = {
      vertical: 'middle',
      horizontal: 'left'
    }

    sheet_p1.getCell('I5').value = '總額:'
    sheet_p1.getCell('I5').alignment = {
      vertical: 'middle',
      horizontal: 'right'
    }

    sheet_p1.mergeCells('J5:K5')
    sheet_p1.getCell('J5').value = summery.deductibleAmount
    sheet_p1.getCell('J5').alignment = {
      vertical: 'middle',
      horizontal: 'left'
    }

    sheet_p1.getCell('I6').value = '服務費:'
    sheet_p1.getCell('I6').alignment = {
      vertical: 'middle',
      horizontal: 'right'
    }

    sheet_p1.mergeCells('J6:K6')
    sheet_p1.getCell('J6').value = agreementFee(summery.agreement)
    sheet_p1.getCell('J6').alignment = {
      vertical: 'middle',
      horizontal: 'left'
    }

    sheet_p1.getCell('I7').value = '優惠折扣:'
    sheet_p1.getCell('I7').alignment = {
      vertical: 'middle',
      horizontal: 'right'
    }

    sheet_p1.mergeCells('J7:K7')
    sheet_p1.getCell('J7').value = agreementDiscount(summery.agreement)
    sheet_p1.getCell('J7').alignment = {
      vertical: 'middle',
      horizontal: 'left'
    }

    sheet_p1.getCell('I8').value = '累計應繳：'
    sheet_p1.getCell('I8').alignment = {
      vertical: 'middle',
      horizontal: 'right'
    }

    sheet_p1.mergeCells('J8:K8')
    sheet_p1.getCell('J8').value = summery.final_amount
    sheet_p1.getCell('J8').alignment = {
      vertical: 'middle',
      horizontal: 'left'
    }

    sheet_p1.getColumn(1).width = 5
    sheet_p1.getColumn(2).width = 15
    sheet_p1.getColumn(7).width = 15
    sheet_p1.getColumn(8).width = 30
    sheet_p1.getColumn(9).width = 30

    sheet_p1.mergeCells('A9:K9')
    sheet_p1.getCell('A9').value = ' '

    sheet_p1.mergeCells('A10:K10')
    sheet_p1.getCell('A10').value = '計程車行程明細表'
    sheet_p1.getCell('A10').alignment = {
      vertical: 'middle',
      horizontal: 'left'
    }

    const rowTitle = [
      'NO.',
      '用車時間',
      '行程代碼',
      '群組',
      '行程類型',
      // '員工編號',
      '姓名',
      '計程車車號',
      '上車時間',
      '上車地點',
      '下車時間',
      '下車地點',
      '行駛里程',
      '司機',
      '總金額',
      '公司支付',
      '員工自付',
      '報帳類型',
      '審核狀態'
    ]

    let sortCustomField = []
    let temp = {}
    state.trips_history.forEach(item => {
      const reserveTime = moment(item.reserve_time * 1000)
      const billingMonth = reserveTime.date() >= company.billing_day ? reserveTime.startOf('month').unix() : reserveTime.subtract(1, 'month').startOf('month').unix()
      if (billingMonth === summery.time) {
        let currentNote = {}
        item.custom_field &&
          item.custom_field.forEach(el => {
            currentNote[el.id] = el.content
          })
        state.custom_field.forEach((item, index) => {
          if (currentNote[item.id] && !temp[item.id]) {
            temp[item.id] = item.title
            sortCustomField.push(item)
            rowTitle.push(item.title)
          }
        })
      }
    })
    sheet_p1.getRow(11).values = rowTitle

    let count = 0
    state.trips_history
      .sort((a, b) => (a.trip_id > b.trip_id ? 1 : -1))
      .reverse()
      .forEach(item => {
        const reserveTime = moment(item.reserve_time * 1000)
        const billingMonth = reserveTime.date() >= company.billing_day ? reserveTime.startOf('month').unix() : reserveTime.subtract(1, 'month').startOf('month').unix()
        if (billingMonth === summery.time) {
          count++
          const business_notes = {}
          item.custom_field &&
            item.custom_field.forEach(el => {
              business_notes[el.id] = el.content
            })
          const rowContent = [
            count, //NO
            moment(item.reserve_time * 1000).format('YYYY/MM/DD HH:mm'), //用車時間
            item.trip_hash, //行程代碼
            item.company_users_unit.groups.name, //群組
            tripCategory[item.trip_category], //行程類型
            // item.company_users.id, //員工編號
            item.company_users_unit.name, //員工姓名
            item.driver_info.plate_number, //車牌
            moment(item.trip_start_time * 1000).format('YYYY/MM/DD HH:mm'), //上車時間
            item.start_address, //上車地點
            moment(item.trip_finish_time * 1000).format('YYYY/MM/DD HH:mm'), //下車時間
            item.end_address || '未輸入', //下車地點
            item.distance ? Number(item.distance) : '-', // 行駛里程
            item.driver_info.last_name + 'ＯＯ',
            item.amount, //總金額
            handleTripTransactions(item).company, //公司支付
            handleTripTransactions(item).self, //員工自付
            receiptType[item.receipt_type], //報帳類型
            item.company_trips.status.tripStatusLocalized() //審核狀態
          ]

          sortCustomField.forEach(item => {
            if (business_notes[item.id]) {
              rowContent.push(business_notes[item.id])
            } else {
              rowContent.push('')
            }
          })
          sheet_p1.addRow(rowContent)
        }
      })

    const buf = await workbook.xlsx.writeBuffer()
    FileSaver.saveAs(
      new Blob([buf]),
      summery.time.toMonth() + '月乘車資訊清冊.xlsx'
    )
  }
  return (
    <React.Fragment>
      <Button
        className={generalClasses.right_button}
        variant="contained"
        color="default"
        onClick={() => onExportTripExcel()}
      >
        <Typography className={classes.text}>
          匯出 {summery.time.toMonth()} 月乘車資訊清冊
        </Typography>
        <Icon className={classes.export_icon} fontSize="small">
          open_in_new
        </Icon>
      </Button>
    </React.Fragment>
  )
}

export default ExportTripAmountTableButton
